import $ from 'jquery';
import React from 'react';
import PropTypes from "prop-types";

# Tooltip component. Thin wrapper around bootstrap tooltip javascript.
export class Tooltip extends React.Component
  @propTypes:
    # Label for link, default to '?'
    label: PropTypes.string
    # Text of tooltip
    text: PropTypes.string.isRequired
    # URL to link tooltip to
    url: PropTypes.string

  @defaultProps:
    label: '?'
    text: null
    url: null

  componentDidMount: ->
    $(this.linkElement).tooltip container: 'body'

  componentDidUpdate: (prevProps) ->
    $(this.linkElement).tooltip 'fixTitle' if this.props.text != prevProps.text

  render: ->
    `<a href={this.props.url || '#'}
        rel="tooltip"
        className="tooltip_link"
        onClick={this._onClick}
        ref={this._setRef}
        title={this.props.text}>{this.props.label}</a>`

  _onClick: (event) =>
    if this.props.url
      true
    else
      event.preventDefault()
      false

  _setRef: (element) =>
    this.linkElement = (element)

modulejs.define 'slzr/components/tooltip',
  ['jquery', 'react', 'prop-types'],
  ($, React, PropTypes) ->
    Tooltip