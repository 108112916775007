import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import PropTypes from "prop-types"
import classNames from "classnames";
import { Tooltip } from './tooltip.js.jsx';

export class DateInputRow extends React.Component
  @propTypes:
    label: PropTypes.string
    tooltip: PropTypes.string
    tooltipUrl: PropTypes.string
    placeholder: PropTypes.string
    type: PropTypes.oneOf(['time', 'date']).isRequired
    # The initial, parsed date value
    initialValue: PropTypes.oneOfType [
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]
    # The initial value of the input field
    initialInputValue: PropTypes.string
    onChange: PropTypes.func
    instanceKey: PropTypes.any
    required: PropTypes.bool
    requiredAsAsterisk: PropTypes.bool
    name: PropTypes.string
    showPreview: PropTypes.bool
    showError: PropTypes.bool

    # Theme-related things
    wrapperElement: PropTypes.elementType.isRequired
    wrapperClassName: PropTypes.string
    labelElement: PropTypes.elementType.isRequired
    labelClassName: PropTypes.string
    inputWrapperElement: PropTypes.elementType.isRequired
    inputWrapperClassName: PropTypes.string

    # Pass in a controlled error message
    errorMessage: PropTypes.string

  @defaultProps:
    type: 'date'
    # Format for initial field value when date
    initialDateFormat: Slzr.Formats.ShortDate
    # Format for initial field value when time
    initialTimeFormat: Slzr.Formats.LongTime
    # Format for date preview
    previewDateFormat: Slzr.Formats.LongDate
    # Format for time preview
    previewTimeFormat: Slzr.Formats.LongTime

    # Error message when invalid date
    dateError: 'Unknown date'
    # Error message when invalid time
    timeError: ''

    # Pass in a controlled error message
    errorMessage: ''

    # Arbitrary identifier, when changes causes the component to be reset (allowing reuse)
    instanceKey: ''

    # Show preview text
    showPreview: true
    # Show unknown (error) text
    showError: false

    # Callback when date is changed
    # Receives:
    #   event object
    #   parsed date, or "error" or "blank"
    #   raw input value
    onChange: (event, current_date, input_value) -> # empty

    # Theme stuff
    wrapperElement: 'label'
    wrapperClassName: ''
    labelElement: 'span'
    labelClassName: ''
    inputWrapperElement: 'div'
    inputWrapperClassName: 'em-basic-input'

    # Require style
    requiredAsAsterisk: false

  # Normalize a date value from either a string or a Date object into a date object for
  # midnight on that date in the browser's time zone
  _normalizeDateValue: (value) ->
    if typeof(value) == 'string'
      moment(value).set(hours: 0, minutes: 0, seconds: 0).toDate()
    else
      value

  _getStateFromProps: (props) =>
    initial_value = null
    current_value = props.initialValue
    is_valid = show_preview = props.initialValue?
    is_blank = !props.initialValue?

    if props.type == 'date'
      date = this._normalizeDateValue(props.initialValue)
      if !date || date.toString() == "Invalid Date"
        date = null
        is_valid = false
        show_preview = false
        is_blank = true

    if date?
      if props.initialValue?
        initial_value = if props.type == 'date'
          if 0 == Date.compare date, Date.today()
            'Today'
          else if 0 == Date.compare date, Date.today().add(1).days()
            'Tomorrow'
          else
            date.toString props.initialDateFormat
      else
        date = props.initialValue.toString props.initialTimeFormat

    return {
      isValid: is_valid
      isBlank: is_blank
      # Preview visibility and content
      showPreview: show_preview
      # Parsed and display value
      currentValue: date #initial_value
      value:        props.initialInputValue || initial_value
    }

  constructor: (props) ->
    super props

    @id_prefix = _.uniqueId('date-input-')

    # Initial state
    @state = Object.assign(
      { focused: false },
      this._getStateFromProps(props)
    )

  UNSAFE_componentWillReceiveProps: (nextProps) ->
    # This code enables a limited amount of external control over the component. When the controlled value changes,
    # the component needs to reset its internal state to match.
    #
    # When any of the conditional checks return, that means the controlled value did not effectively change.
    # This doesn't use object identity, rather the value of +initialValue+ matching the stored internal date.
    #
    # instanceKey indicates the component needs a complete reset. It serves the same purpose as key. If the instanceKey
    # value changes, it's assumed nextProps is unrelated to the previous values.
    if nextProps.instanceKey == this.props.instanceKey
      # If instanceKey hasn't changed, *but* the input is a date input *and* initialValue has changed
      # *and* the new date is different from the currently input date, *and* the current input is valid
      # *and* the input is focused, only then reset state
      #
      # Otherwise, this represents the same "input". If operating in date mode, if +initialValue+ is present and differs
      # from the current prop, a parent is attempting to control this component and change the value.
      #
      # If the value changes the effective date, or changes from a blank or error state to a valid date, update
      # the state to reflect the new value. This updates the textual input field as well.
      #
      # However, if the element is currently focused, do *not* update any state. This prevents disruption to the user
      # while they're typing, but may cause the input to fall out of sync with the controlled value.
      #
      # If the element is not focused, but the effective date value did not change, do not reset the input field state.
      if nextProps.initialValue? && nextProps.initialValue != "" &&
        !this.state.focused && nextProps.type == 'date' &&
        nextProps.initialValue != this.props.initialValue
          new_date = this._normalizeDateValue(nextProps.initialValue)
          # If there's a valid date input, make sure the date changes.
          # If there's not a valid date input, allow the reset to proceed
          if this.state.isValid && 0 == Date.compare new_date, this.state.currentValue
            return

      else
        # initialValue is not present, so there's nothing to do
        return

    nextState = this._getStateFromProps(nextProps)
    this.setState nextState



  # Handle change of input
  _inputChanged: (evt) =>
    input_value = evt.target.value

    # Parse input into a date or error
    current_value = if !input_value || input_value.isBlank()
      'blank'
    else
      d = Date.parse(input_value, bias: 'future_date')
      if d instanceof Date
        d
      else
        'error'

    @props.onChange? evt, current_value, input_value

    @setState
      isValid: current_value != 'error'
      isBlank: current_value == 'blank'
      showPreview: current_value != 'blank'
      currentValue: current_value
      value: input_value

  # Handle a key press.
  #
  # If an onEnterKey callback is defined, captures the ENTER keypress and triggres that, otherwise
  # lets it continue.
  #
  # The callback is expected to return true if the keypress should be blocked.
  _keyPressed: (evt) =>
    if @props.onEnterKey? && evt.key == 'Enter'
      evt.preventDefault() if @props.onEnterKey(evt)

  _onFocus: (event) =>
    this.setState(focused: true)

  _onBlur: (event) =>
    this.setState(focused: false)

  # Format the given Date for the preview
  #
  # date should be a Date representing the parsed input value,
  # or "blank" or "error" for those states
  _formatPreview: (date) =>
    if @props.type == 'date'
      switch date
        when 'blank' then ''
        when 'error' then @props.dateError
        else date.toString @props.previewDateFormat
    else # time
      switch date
        when 'blank' then ''
        when 'error' then @props.timeError
        else date?.toString @props.previewTimeFormat

  render: =>
    preview_el = if this.props.showError && this.props.errorMessage
      `<div className={this.props.errorClassName}>{this.props.errorMessage}</div>`
    else if @state.currentValue == 'error' && @props.showError
      `<p id={this.id_prefix + '-preview'} role="alert" className="preview error">{this._formatPreview(this.state.currentValue)}</p>`
    else if @state.currentValue != 'blank' && @props.showPreview && @state.showPreview
      `<p id={this.id_prefix + '-preview'} className="preview">{this._formatPreview(this.state.currentValue)}</p>`
    else
      null

    if this.props.label
      label_class = if this.props.showError then this.props.labelErrorClassName else this.props.labelClassName
      tooltip_tag = unless _.isBlank(this.props.tooltip)
        `<Tooltip text={this.props.tooltip}
                  url={this.props.tooltipUrl} />`

      decoratedLabel = this.props.label
      if this.props.required && this.props.requiredAsAsterisk then decoratedLabel = "#{this.props.label}*"
      required_tag = `<sup className="required">REQUIRED</sup>` if this.props.required && !this.props.requiredAsAsterisk

      label_tag = `<this.props.labelElement htmlFor={this.props.labelElement === 'label' ? this.props.name : undefined}
                                            className={label_class}>
                     {decoratedLabel} {required_tag} {tooltip_tag}
                   </this.props.labelElement>`
    else
      label_tag = null

    is_invalid = this.props.formHasDateError || (this.state.currentValue == 'error' && this.props.showError)

    # When inputWrapperElement is a React.Fragment, warnings are thrown for the className property
    # Therefore, only include the className property when a class is set
    # and check if the wrapper component is a fragment
    input_wrapper_props = {}
    if this.props.inputWrapperClassName && !this.props.inputWrapperElement == React.Fragment
      input_wrapper_props.className = this.props.inputWrapperClassName

    # Combine the theme prop inputClassName with the specified className
    input_class = if this.props.showError then this.props.inputErrorClassName else this.props.inputClassName
    input_classes = classNames input_class, this.props.className

    `<this.props.wrapperElement htmlFor={this.props.wrapperElement === 'label' ? this.props.name : undefined}
                                className={this.props.wrapperClassName}>
      {label_tag}
      <this.props.inputWrapperElement {...input_wrapper_props}>
        <input type="text" value={this.state.value || ''}
               className={input_classes}
               aria-describedby={this.id_prefix + '-preview'}
               aria-invalid={is_invalid}
               name={this.props.name}
               id={this.props.name}
               onChange={this._inputChanged}
               onKeyDown={this._keyPressed}
               onFocus={this._onFocus}
               onBlur={this._onBlur}
               placeholder={this.props.placeholder} />
      </this.props.inputWrapperElement>
      {preview_el}
    </this.props.wrapperElement>`


modulejs.define 'slzr/components/date_input_row',
  ['jquery', 'react', 'slzr/components/tooltip', 'underscore', 'moment', 'prop-types', 'classnames'],
  ($, React, Tooltip, _, moment, PropTypes, classNames) ->
    DateInputRow
