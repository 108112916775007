# Basic utility functions

# Namespace
# from https://github.com/jashkenas/coffee-script/wiki/FAQ

namespace = (target, name, block) ->
  [target, name, block] = [(if typeof exports isnt 'undefined' then exports else window), arguments...] if arguments.length < 3
  top = target
  target = target[item] or= {} for item in name.split '.'
  block target, top

# fixed namespace issue
# https://stackoverflow.com/questions/10484872/uncaught-referenceerror-namespace-is-not-defined-when-namespacing-in-coffeescri
(exports ? @).namespace = namespace

# Ensure LOCALIST_SETTINGS exists, and add it into modulejs namespace
window.LOCALIST_SETTINGS or= {}
modulejs.define 'LOCALIST_SETTINGS', LOCALIST_SETTINGS

# Define Slzr namespace as an exported object from this module.
#
# Outside of webpack, this defines a global `Slzr` variable.
#
# An additional first argument could be passed into namespace to force it onto window, but
# by not doing so and explicitly treating it as a global in webpack, this file fits into native
# modules more nicely.
namespace 'Slzr', (exports, top) ->
  exports.Debug = window.LOCALIST_SETTINGS.development

  # Try to expose our jquery, moment, and underscore glboally
  exports.jQuery = window.jQuery
  exports.moment = window.moment
  exports._ = window._

  # Common format strings
  exports.Formats =
    # Date/Time preview formats, used for input previews and instance lists
    DateTime:
      'en-US':
        # Long date format, with words
        LongDate: 'ddd, MMM dd, yyyy'
        # Short date format
        ShortDate: 'M/d/yyyy'
        # Long time format
        LongTime: 'h:mm tt'
      'en-GB':
        # Long date format, with words
        LongDate: 'ddd, MMM dd, yyyy'
        # Short date format
        ShortDate: 'd/M/yyyy'
        # Long time format
        LongTime: 'h:mm tt'
      'en-AU':
        # Long date format, with words
        LongDate: 'ddd, MMM dd, yyyy'
        # Short date format
        ShortDate: 'd/M/yyyy'
        # Long time format
        LongTime: 'h:mm tt'

  # Activate the appropriate locale for the default formats
  exports.Formats.DateTime.active = exports.Formats.DateTime[LOCALIST_SETTINGS.locale] || exports.Formats.DateTime['en-US']
  exports.Formats.LongDate = exports.Formats.DateTime.active.LongDate
  exports.Formats.ShortDate = exports.Formats.DateTime.active.ShortDate
  exports.Formats.LongTime = exports.Formats.DateTime.active.LongTime

# Under webpack, `namespace` defines the namespace on `exports`, rather than
# the global window object.
#
# Code following this assumes `Slzr` is implicitly defined via the global variable, so we ensure it's set here.
Slzr = (exports ? @).Slzr

# Register our base modules, and globals we use
modulejs.define 'jquery', -> Slzr.jQuery
modulejs.define 'slzr', ['jquery', 'underscore'], Slzr
modulejs.define 'BackgroundCheck', BackgroundCheck


# Configure datejs with the proper locale
Date.CultureInfo = Date.AvailableCultureInfo[LOCALIST_SETTINGS.locale] || Date.AvailableCultureInfo['en-US']

# Show a flash notice with the specified message
#
# msg: the message to show
window.flashNotice = flashNotice = (msg) ->
  message_response_message = Slzr.jQuery('#message-response-message')
  message_response = Slzr.jQuery('#message-response')

  if message_response_message.length > 0
    message_response_message.html(msg)
  else
    message_response.html(msg)

  message_response.fadeIn('slow')

# Hide the notice
window.hideNotice = hideNotice = -> Slzr.jQuery('#message-response').fadeOut('fast')

# Check all checkboxes in a container
window.checkAll = checkAll = (container, el) ->
  container = Slzr.jQuery(container)
  el = Slzr.jQuery(el)
  container = el.closest('form') unless container.length > 0

  new_state = el.prop('checked')

  container.find('input[type="checkbox"]:enabled')
    .prop('checked', new_state)

  el.prop('checked', new_state)

String.prototype.isBlank = -> this.match /^\s*$/

# Extend underscore with isBlank
_.mixin isBlank: (str) ->
  _.isUndefined(str) || _.isNull(str) || str.trim().length == 0

# Disable a submit button that is an ajax form submit.
# Basically the rails helper, just without the reset.
# function disableFormSubmit(el, msg) {
window.disableFormSubmit = disableFormSubmit = (el, msg) ->
  if window.hiddenCommit
    window.hiddenCommit.setAttribute 'value', el.value
  else
    window.hiddenCommit = el.cloneNode(false)
    window.hiddenCommit.setAttribute 'type', 'hidden'
    el.form.appendChild hiddenCommit

  el.setAttribute 'originalValue', el.value

  # Deferring this allows Safari to submit without needing onsubmit/submit called explicitly,
  # while preventing Firefox from submitting twice
  _.defer ->
    el.disabled = true
    el.value = msg

# Disables a submit button with a message.
# Doesn't do anything else but change some attributes.
window.disableButton = disableButton = (el, msg) ->
  if el = Slzr.jQuery(el)
    if el[0].tagName == 'A'
      # Mess with inner-html for links
      el.attr('originalValue', el.html())
        .prop('disabled', true)
        .html(msg)
    else
      el.attr('originalValue', el.val())
        .prop('disabled', true)
        .val(msg)

# Reenable's a submit button disabled with disableButton
window.enableButton = enableButton = (el) ->
  if el = Slzr.jQuery(el)
    if el[0].tagName == 'A'
      el.prop('disabled', false)
        .html(el.attr('originalValue'))
        .attr('originalValue', null)
    else
      el.prop('disabled', false)
        .val(el.attr('originalValue'))
        .attr('originalValue', null)

window.disableOrSubmitWithValidation = disableOrSubmitWithValidation = (el, fn, msg) ->
  if fn()
    Slzr.jQuery(el).val(msg)
    return true
  else
    return false

# Do a date-js type parse of a date in a lightbox, that doesn't use dropdowns internally
window.parseDate = parseDate = (lbkey) ->
  input_el = Slzr.jQuery("#x-date-input-#{lbkey}")
  output_el = Slzr.jQuery("#x-date-#{lbkey}")
  preview_el = Slzr.jQuery("#x-date-preview-#{lbkey}")
  error_el = Slzr.jQuery("#x-date-error-#{lbkey}")
  input = input_el.val()

  if input.isBlank()
    error_el.show()
    preview_el.html('').hide()
    return false
  else
    parsed_date = Date.parse(input, bias: 'future_date')
    if parsed_date
      error_el.hide()
      preview_el.html(parsed_date.toString(Slzr.Formats.LongDate)).show()
      output_el.val(parsed_date.toString('yyyy-MM-dd'))
      return true
    else
      preview_el.html('').hide()
      error_el.show()
      return false

# Update the current URL, changing the specified GET parameters without affecting any others
window.updateUrl = updateUrl = (params) ->
  new_url = Slzr.jQuery.string(location.search.replace('+', ' ')).toQueryParams()
  new_url = Slzr.jQuery.extend({}, new_url, params)
  location.search = '?' + Slzr.jQuery.param(new_url)

# Hide or show el based on the value of visible
window.setVisible = setVisible = (el, visible=true) ->
  if visible
    Slzr.jQuery(el).show()
  else
    Slzr.jQuery(el).hide()

# Truncate a string to the specified length, optionally on a word boundary
String.prototype.truncate = (size, on_word=true, suffix='...') ->
  if @length > size
    # cut it down to size
    str = @substring(0, size)
    str = str.split(/\s+/).slice(0, -1).join(' ')
    str + suffix
  else
    this

# POLYFILLS

# Sub String.prototype.normalize in older browsers (namely, IE11 and older Android) to just return the string.
# If we ever need full international support, we may want to look at the +unorm+ library on npm.
if typeof(String.prototype.normalize) != 'function'
  String.prototype.normalize = -> this

modulejs.define 'slzr/utils/truncate', ->
  # Truncate a string, optionally cutting off mid word
  truncate = (string, size, on_word=true, suffix='...') ->
    if string.length > size
      wanted_size = size - suffix.length
      if on_word
        string = string.substring(0, wanted_size)
        string = string.substring(0, string.lastIndexOf(' '))
        string = string + suffix
      else
        string.substring(0, wanted_size) + suffix
    else
      string

# Clear input placeholders
window.clearPlaceholders = clearPlaceholders = (form) ->
  Slzr.jQuery("input[placeholder]", form).each ->
    $this = Slzr.jQuery(this)
    $this.val('') if $this.val() == '' || $this.val() == $this.attr('placeholder')

# Detect browser
#
# Mainly needed to work around IE7 crashes related to the photo manager
namespace 'Slzr.Browser', (exports, top) ->
  exports.IE = false
  exports.version = false
  exports.chrome = false
  exports.safari = false
  exports.opera = false
  exports.firefox = false

  if navigator.appName == 'Microsoft Internet Explorer'
    exports.IE = true
    
    ua = navigator.userAgent
    re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})')

    if (m = re.exec(ua)) != null
      exports.version = parseFloat(m[1])

  for browser in ['chrome', 'safari', 'firefox', 'opera']
    exports[browser] = navigator.userAgent.toLowerCase().indexOf(browser) > -1
    break

# mini-jQuery plugin to wrap on('click', '[data-action]', ()-> ) type calls
# in a little more useful way
(($) ->
  $.fn.onAction = (action, callback) ->
    $(this).on 'click', '[data-action="' + action + '"]', (evt) ->
      callback.call(this, evt)
      evt.preventDefault()

  # Update a URL by adding/removing a parameter
  $(document).on 'click ifChanged', '[data-action="toggle-param"]', (evt) ->
    $this = $(this)
    key = $this.attr('name')
    value = if $this.prop('checked') then $this.val() else null

    new_param = {}
    new_param[key] = value
    updateUrl new_param

  $(document).onAction 'close-lightbox', (evt) ->
    hideIbox()
    open_url = $(this).data('lightbox-open-url')
    window.open($(this).attr('href')) if open_url

  $(document).on 'change', '[data-action="check-all"]', (evt) ->
    $this = $(this)
    container = $this.data('check-all')
    checkAll(container, $this);
    evt.preventDefault()
    evt.stopPropagation()

  # Switch between tabs, by hiding/showing elements and toggling a class
  #
  # data-action="switch-tab"
  #
  # Additional data attributes:
  #   data-tab-target: Target tab to switch to (defined by data-tab on appropriate data-tab-group)
  #   data-tab-group: Defines a tab "group" to associate elements together
  $(document).onAction 'switch-tab', (event) ->
    event.preventDefault()
    $this = $(this)
    tab_group = $this.data('tab-group')
    tab_target = $this.data('tab-target')
    $(document).trigger 'slzr:switch-tab', [tab_group, tab_target]

  $(document).on 'slzr:switch-tab', (event, tab_group, tab_target) ->
    all_tabs = $("[data-tab-group=\"#{tab_group}\"][data-tab]")
    all_links = $("[data-tab-group=\"#{tab_group}\"][data-tab-target]")

    target_tab = all_tabs.filter("[data-tab=\"#{tab_target}\"]")
    selected_links = all_links.filter("[data-tab-target=\"#{tab_target}\"]")

    all_links.removeClass('selected')
    selected_links.addClass('selected')

    all_tabs.hide()
    target_tab.show()

    $(document).trigger 'slzr:switched-tab', [tab_group, tab_target]

  # Disable links with a class that ends with `_export_action`
  $(document).on 'click', "button[data-action$='_export_action']", ->
    # Set disable with text
    $this = $(this)
    $label = $('span.label', this)
    what = $this.data('export-label') || 'CSV'

    old_label = $label.html()
    $label.html("Requesting #{what} ...").attr('disabled', true)
    $this.attr('disabled', true)

    # Do AJAX POST
    $.post($this.attr('href'))
      .then (response) ->
        return unless response.queued
        flashNotice response.notice
        $label.html("Requested #{what}")

        # Track any google analytics events that happened
        if response.track_events?
          for event in response.track_events
            Slzr.trackGAEvent event.category, event.action, event.label, event.value

      .fail (response) ->
        flashNotice 'Unable to request report'
        $label.html(old_label)
        $this.attr('disabled', false)

)(Slzr.jQuery)